<template>
  <div class="ideas">
    <h1>
      Ваше предложение?
      <router-link :to="{ name: 'Home' }">🏠</router-link>
    </h1>
    <textarea v-model="idea" />
    <button @click="doSend">Отправить</button>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Ideas",
  data() {
    return { idea: "" };
  },
  methods: {
    doSend() {
      axios
        .post(
          `${
            process.env.NODE_ENV === "production"
              ? "https://wsega.ru"
              : "http://192.168.50.81:3303"
          }/idea`,
          { idea: this.idea }
        )
        .then(() => {
          this.$router.push({ name: "Home" });
        });
    },
  },
};
</script>

<style scoped lang="scss">
.ideas {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  flex-flow: column;

  h1 {
    color: deepskyblue;
  }

  textarea {
    width: 90%;
    height: 90%;
    border: 2px green solid;
  }

  button {
    margin: 24px;
    padding: 16px;
    border: 2px goldenrod solid;
    background-color: transparent;
    color: goldenrod;
    font-weight: bolder;
    font-size: 16px;
  }
}
</style>
