<template>
  <div class="home">
    <div class="link">
      <a
        href="https://www.youtube.com/@wsega?sub_confirmation=1"
        class="youtube"
      >
        Перейти Youtube
      </a>
      <router-link class="idea" :to="{ name: 'ideas' }">
        Предложить идею
        <span class="small">Игры, Мультика, Программы</span>
      </router-link>
      <a href="https://t.me/wsega" class="telegram"> Перейти Telegram </a>
      <a href="https://boosty.to/wsega" class="boosty"> Boosty </a>
    </div>
    <iframe
      width="480"
      height="270"
      src="https://dzen.ru/embed/vNIa4y_J8kWs?from_block=partner&from=zen&mute=1&autoplay=1&tv=0"
      allow="autoplay; fullscreen; accelerometer; gyroscope; picture-in-picture; encrypted-media"
      frameborder="0"
      scrolling="no"
      allowfullscreen
    />

    <div class="link">
      <a class="random-game" href="https://wsega.ru/polzuha">
        <div class="row">Игра <span class="name">ПОЛЗУХА</span></div>
      </a>
      <router-link class="random-game" :to="{ name: 'random' }">
        <div class="row">Игра <span class="name">Случай</span></div>
      </router-link>
      <a class="random-game" href="https://wsega.ru/taschuha">
        <div class="row">Игра <span class="name">Тащуха</span></div>
      </a>
    </div>
    <div class="link">
      <router-link class="mc-wsega" :to="{ name: 'minecraft' }">
        <img src="../assets/mc.wsega.icon.png" alt="mc.wsega.ru" height="64" />
        <div class="row">MINECRAFT java SERVER</div>
        <div class="row">mc.wsega.ru</div>
      </router-link>
    </div>

    <div class="image">
      <img alt="WseGa Logo" src="../assets/vector.svg" class="logo" />
    </div>
    <div class="y-button">
      <div
        class="g-ytsubscribe"
        data-channelid="UC4Uw6vk28GJyBeElSbhewNQ"
        data-layout="default"
        data-count="default"
      />
    </div>
    <div class="videos">
      <iframe
        src="https://www.youtube.com/embed/sM59yZUk3Ag?controls=0"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      />
      <iframe
        src="https://www.youtube.com/embed/lajwqMtKK_A?controls=0"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      />
      <iframe
        src="https://www.youtube.com/embed/moAzoVX1E4c?controls=0"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {},
};
</script>

<style lang="scss">
.home {
  .small {
    font-size: 0.5rem;
  }

  .link {
    padding: 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  flex-flow: column;

  .image {
    height: 100%;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .videos {
    display: flex;
    width: 100%;
    overflow: auto;
    justify-content: center;

    iframe {
      padding: 16px;
    }
  }

  .link {
    a {
      align-items: center;
      justify-content: center;
      display: flex;
      flex-flow: column;
      text-decoration: unset;
      margin: 16px;
      padding: 8px;
      color: black;

      &:visited {
        color: black;
      }

      &.youtube {
        color: darkred;
        border: 2px darkred solid;
      }

      &.telegram {
        color: deepskyblue;
        border: 2px deepskyblue solid;
      }

      &.idea {
        color: green;
        border: 2px green solid;
      }

      &.boosty {
        color: orange;
        border: 2px orange solid;
      }

      &.mc-wsega {
        color: gray;
        border: 2px gray solid;
        background-color: #fafbee;
        & > div {
          color: slateblue;
          font-weight: bolder;
        }
      }

      &.random-game {
        color: gray;
        border: 2px gray solid;
        background-color: yellow;

        span.name {
          color: slateblue;
          font-weight: bolder;

          &:before {
            content: '"';
          }

          &:after {
            content: '"';
          }
        }
      }
    }
  }
}
</style>
