<template>
  <div class="minecraft">
    <h1>
      Minecraft Java WseGa server
      <router-link :to="{ name: 'Home' }">🏠</router-link>
    </h1>
    <h1>
      <a href="#" @click="doCopyToClipboard">
        Скопировать адрес сервера
        <span :class="{ copied, failed }">mc.wsega.ru</span>
      </a>
    </h1>
    <h2>mc.wsega.ru</h2>
  </div>
</template>

<script>
export default {
  name: "MinecraftView",
  data() {
    return {
      copied: false,
      failed: false,
    };
  },
  methods: {
    doCopyToClipboard() {
      navigator.clipboard
        .writeText("mc.wsega.ru")
        .then(() => (this.copied = true))
        .catch(() => (this.failed = true));
    },
  },
};
</script>

<style scoped lang="scss">
.minecraft {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  flex-flow: column;

  h1 {
    color: deepskyblue;

    a {
      text-decoration: none;
      color: green;
      span {
        color: cornflowerblue;
        background: palegreen;
        &.copied {
          background: cornflowerblue;
          color: palegreen;
        }
        &.failed {
          background: orangered;
        }
      }
    }
  }

  textarea {
    width: 90%;
    height: 90%;
    border: 2px green solid;
  }

  button {
    margin: 24px;
    padding: 16px;
    border: 2px goldenrod solid;
    background-color: transparent;
    color: goldenrod;
    font-weight: bolder;
    font-size: 16px;
  }
}
</style>
