<template>
  <div class="random-wrapper">
    <div class="random-game">
      <router-link :to="{ name: 'Home' }">🏠</router-link>
      <p>Выберите сложность</p>
      <div class="buttons small">
        <button
          @click="maxNumber = o.n"
          v-for="o in options"
          :key="o.n"
          :class="{ bg: o.n === maxNumber }"
        >
          {{ o.l }}
        </button>
      </div>
      <p>
        Введите число от <b>0</b> до <b>{{ maxNumber - 1 }}</b> и нажмите одну
        из кнопок.
      </p>
      <div class="buttons">
        <input class="number" type="text" v-model="candidate" />
      </div>
      <div class="buttons">
        <button @click="doDice('Я угадал')">Я угадал</button>
        <button @click="doDice('Я знаю')">Я знаю</button>
        <button @click="doDice('Зачем?')">Зачем?</button>
      </div>
      <div class="results">
        <div v-for="(r, i) in results" :key="i" class="r">
          <span class="b">{{ r.name }}</span>
          <span class="suggest">
            <span
              v-for="(c, j) in r.suggestion.toString()"
              :key="'z-' + j"
              :class="{
                g: c === r.real.toString()[j],
                y: r.real.toString().includes(c),
              }"
            >
              {{ c }}
            </span>
          </span>
          <span
            :class="{
              c: r.suggestion.toString() === r.real.toString(),
              w: r.suggestion.toString() !== r.real.toString(),
            }"
          />
          <span class="real">{{ r.real }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RandomGame",
  data() {
    return {
      candidate: undefined,
      maxNumber: 10000,
      results: [],
      options: [
        { n: 5, l: "Легко" },
        { n: 10, l: "Нормально" },
        { n: 100, l: "Сложно" },
        { n: 1000, l: "Невозможно" },
        { n: 10000, l: "В лотерею проще" },
      ],
    };
  },
  methods: {
    doDice(name) {
      this.results = [
        {
          name,
          suggestion: this.candidate || "",
          real: Math.floor(Math.random() * this.maxNumber),
        },
        ...this.results,
      ];
      this.candidate = undefined;
    },
  },
};
</script>

<style scoped lang="scss">
.random-wrapper {
  a,
  a:visited {
    text-decoration: none;
    font-weight: bolder;
    font-size: 2em;
    &:hover {
      color: green;
    }
  }
  display: flex;
  width: 100%;
  height: 100%;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  .random-game {
    overflow: hidden;
    height: 50%;
    padding: 16px;
    border: 2px solid green;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    .buttons {
      input {
        border: 4px solid green;
        margin: 8px;
        padding: 8px;
        text-align: center;
        font-size: 1rem;
        font-weight: bolder;
        color: darkgreen;
      }
      &.small {
        button {
          margin: 2px;
          padding: 2px;
          border-width: 1px;
          font-size: 0.7em;
        }
      }
      button {
        &.bg {
          background: slateblue;
        }
        margin: 8px;
        padding: 8px;
        border: 4px solid deepskyblue;
        background: yellow;
        font-weight: bolder;
        font-size: 1.3rem;
        color: deepskyblue;
      }
    }
    .results {
      display: flex;
      flex-flow: column;
      max-height: 50%;
      overflow: auto;
      .r {
        padding: 2px 4px 2px 4px;
        margin: 4px;
        border: 2px slateblue solid;
        display: flex;
        align-items: center;
        justify-content: center;
        .b {
          font-weight: bolder;
        }
        .suggest {
          color: red;
          margin-left: 8px;
          margin-right: 8px;
          display: flex;
          span {
            padding: 0;
            margin: 0;
          }
          .y {
            color: #5e3012;
          }
          .g,
          .g.y {
            color: green;
          }
        }
        .real {
          color: green;
          font-weight: bolder;
        }
        .c {
          &:before {
            margin: 2px;
            color: green;
            content: "☑";
            font-size: 2rem;
          }
        }
        .w {
          &:before {
            margin: 2px;
            color: red;
            content: "☒";
            font-size: 1rem;
            align-items: center;
            text-align: center;
            vertical-align: center;
            justify-content: center;
          }
        }
      }
    }
  }
}
</style>
