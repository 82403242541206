var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"random-wrapper"},[_c('div',{staticClass:"random-game"},[_c('router-link',{attrs:{"to":{ name: 'Home' }}},[_vm._v("🏠")]),_c('p',[_vm._v("Выберите сложность")]),_c('div',{staticClass:"buttons small"},_vm._l((_vm.options),function(o){return _c('button',{key:o.n,class:{ bg: o.n === _vm.maxNumber },on:{"click":function($event){_vm.maxNumber = o.n}}},[_vm._v(" "+_vm._s(o.l)+" ")])}),0),_c('p',[_vm._v(" Введите число от "),_c('b',[_vm._v("0")]),_vm._v(" до "),_c('b',[_vm._v(_vm._s(_vm.maxNumber - 1))]),_vm._v(" и нажмите одну из кнопок. ")]),_c('div',{staticClass:"buttons"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.candidate),expression:"candidate"}],staticClass:"number",attrs:{"type":"text"},domProps:{"value":(_vm.candidate)},on:{"input":function($event){if($event.target.composing)return;_vm.candidate=$event.target.value}}})]),_c('div',{staticClass:"buttons"},[_c('button',{on:{"click":function($event){return _vm.doDice('Я угадал')}}},[_vm._v("Я угадал")]),_c('button',{on:{"click":function($event){return _vm.doDice('Я знаю')}}},[_vm._v("Я знаю")]),_c('button',{on:{"click":function($event){return _vm.doDice('Зачем?')}}},[_vm._v("Зачем?")])]),_c('div',{staticClass:"results"},_vm._l((_vm.results),function(r,i){return _c('div',{key:i,staticClass:"r"},[_c('span',{staticClass:"b"},[_vm._v(_vm._s(r.name))]),_c('span',{staticClass:"suggest"},_vm._l((r.suggestion.toString()),function(c,j){return _c('span',{key:'z-' + j,class:{
              g: c === r.real.toString()[j],
              y: r.real.toString().includes(c),
            }},[_vm._v(" "+_vm._s(c)+" ")])}),0),_c('span',{class:{
            c: r.suggestion.toString() === r.real.toString(),
            w: r.suggestion.toString() !== r.real.toString(),
          }}),_c('span',{staticClass:"real"},[_vm._v(_vm._s(r.real))])])}),0)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }